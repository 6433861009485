<style>
  .create-nav{
    position: sticky;
    top: 0;
     background: RGBA(225, 227, 249, 1)!important;
    z-index: 1001;
    height: 45px;
    padding: 0px 13px 0 15px;
    border-bottom: 1px solid #eeeeee;
  }
  .create-nav li{
    float: left;
    height: 40px;
    line-height: 44px;
    color: #8a8a8a;
    margin-right: 20px;
  }
  .create-nav li span{
    position: relative;
    top: 1px;
    border-bottom: 2px solid transparent;
  }
  .create-nav li.is-active{
    font-size: 18px;
    color: #000;
    font-family: "苹方 粗体";
    font-weight: 600;
  }
  .create-nav li.is-active span{
    display: block;
    border-bottom: 2px solid #000;
  }
  .Home_page{
    float: right!important;
    height: 28px!important;
    margin-right: 0!important;
  }
  .Home_page_img{
padding: 9px 0;
  }
  .Home_page_img2{
    margin-right: 10px;
  }
</style>
<template>
  <div class="create">
    <!-- <div class="create-nav">
      <router-link to="/">创作</router-link>
      <router-link to="/create/course">教程</router-link>
    </div> -->
    <ul class="create-nav">
      <template>
        <li class="navlist"
        v-for="item in createList[0].children"
        :class="$route.path===item.path?'is-active':''"
        @click="selectCreate(item.path)"
        :key="item.path">
          <span>{{item.meta.title}}</span>
        </li>
      </template>
      <li class="Home_page">
        <!-- <img class="Home_page_img Home_page_img2" width="28" height="28" src="../assets/image/navleftimg.png" alt=""> -->
        <img class="Home_page_img" width="28" height="28" @click="return_home" src="../assets/image/Home_page_img.png" alt="">
      </li>
    </ul>
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'home',
  data () {
    return {
      activeIndex: 0,
      menuList: [],
      return_homeurl: ''
    }
  },
  created () {
  },
  computed: {
    createList: function () {
      return this.$router.options.routes.filter(function (item) {
        if (item.name === 'create') {
          return item
        }
      })
    }
  },
  components: {},
  mounted () {
    // console.log(this.$router.options.routes)
  },
  methods: {
    return_home () {
      this.axios({
        method: 'get',
        url: '/v1/getMxsjUrl ',
        params: {}
      }).then((res) => {
        // console.log(res)
        window.location.href = res.data
        // this.return_homeurl = res.data
      })
    },
    selectCreate (path) {
      this.$router.push({ path: path })
    }
  }
}
</script>
